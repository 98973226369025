<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="body-PB">
    <iframe title="Devoluciones" width="100%" height="100%"
      src="https://app.powerbi.com/view?r=eyJrIjoiYTI5YTcxM2MtY2FhYS00Mzc2LWI4MTItMzFjMDc5NDRhYjAwIiwidCI6IjllMDQ0ZjE3LWM3M2MtNGU1ZC04NWViLTMyNDNlNTU1YjczOSIsImMiOjR9&pageName=ReportSection7cdb283cd09780dccd96"
      frameborder="0" allowFullScreen="true"></iframe>
  </div>
</template>
        
        <!-- #################################################################################### -->
        <!-- ###### Sección de Scripts                                                     ###### -->
        <!-- #################################################################################### -->
<script>
export default {
  name: 'Devoluciones',
}
</script>
        
        <!-- #################################################################################### -->
        <!-- ###### Sección de styles                                                      ###### -->
        <!-- #################################################################################### -->
<style scoped>
.body-PB {
  height: 93% !important;
}
</style>